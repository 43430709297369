import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook6/image/imgPortadaEbook6.png"
import img1 from "@components/pageEbook/ebooks/ebook6/image/imgCard1.png"
import img2 from "@components/pageEbook/ebooks/ebook6/image/imgCard2.png"
import img3 from "@components/pageEbook/ebooks/ebook6/image/imgCard3.png"
import slider1 from "@components/pageEbook/ebooks/ebook6/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook6/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook6/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook6/image/imgSilder4.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      7 tendencias para Ecommerce para el 2022
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      Los usuarios tienen una nueva modalidad de compra y de consumo. Ante un
      mayor incremento de consumo online es una prioridad cuidar la calidad de
      atención.
      <br /> <br />
      Por eso, con el fin de impulsar los negocios online, en este e-book
      conocerás un listado de 7 principales tendencias que le esperan a un
      e-commerce durante este 2022.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    La omnicanalidad ayuda a los clientes a interactuar con tu negocio por
    diferentes canales digitales sin que se pierda la calidad del servicio al
    cliente.
    <br /> <br />
    Al construir una sola comunicación en todos tus canales, hace que la imagen
    del negocio sea más reconocible. Esto trae como resultado que tus clientes
    regresen.
  </p>
)
const textTwo = (
  <p>
    WhatsApp es uno de los medios más cercanos para los usuarios. Permite
    comunicarse con los clientes en un canal que ya tienen instalado en su
    teléfono.
    <br /> <br />
    Mediante su API, WhatsApp permite enviar notificaciones personalizados sin
    quebrantar la privacidad de los clientes.
  </p>
)
const textThree = (
  <p>
    Con un chatbot el cliente se olvida de esperar por una respuesta. Además, un
    bot puede ser utilizado como un autoservicio, es decir, que los usuarios se
    pueden atender a sí mismos.
  </p>
)

const data = {
  start: {
    support: "Ebook | Ecommerce",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-ebook6",
    link: linkForm,
    href: "/ebook-6.pdf",
    brochure: "ebook-tendencias-para-Ecommerce",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "Omnicanalidad en los negocios",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "WhatsApp como un canal potencial de ventas",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Implementación de chatbots en canales digitales",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Sobre la importancia de crear una estrategia omnicanal.",
      },
      {
        key: 2,
        text: "Implementar WhatsApp como un canal principal en un ecommerce.",
      },
      {
        key: 3,
        text: "Consolidar estrategias de productos en los Marketplace.",
      },
      {
        key: 4,
        text: "Crear chatbots en los canales digitales de un ecommerce.",
      },
      {
        key: 5,
        text: "Reconocer la importancia de los quick-commerce y los nuevos métodos de pago.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
